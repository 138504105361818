<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE ASPECTOS AMBIENTALES:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-cogs fa-3x"></i> <br>
                            <span class="lg-numero">{{listaAspectos.length}}</span>
                            <br>
                            <span class="text-muted">Aspectos</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Aspectos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col md="6" class="my-2">
                            <b-button block variant="custom" :to="{name: 'Evaluación 14001'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('004-14001-EVA-ASP','c')==1" md="6" class="my-2">
                            <b-button block @click="modalRegistrarAspecto = true" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo aspecto
                            </b-button>
                        </b-col>

                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Aspectos registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaAspectos" :fields="campoAspectos" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('004-14001-EVA-ASP','u')==1" @click="abrirModalActualizarAspecto(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('004-14001-EVA-ASP','d')==1" @click="eliminarAspecto(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalRegistrarAspecto">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo aspecto </span>
            </h6>
            <CButtonClose @click="modalRegistrarAspecto = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarAspecto)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProceso =>comboProceso.idProceso" @input="listarActividadesSGMA()" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosAspecto.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosAspecto.idProceso" :options="comboProceso" @search:blur="blurProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Procesos 14001'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="actividad" rules="required" v-slot="{errors}">
                            <b-form-group label="Actividad:" class="mb-2">
                                <v-select :reduce="comboActividad =>comboActividad.idActividad" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosAspecto.idActividad , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosAspecto.idActividad" :options="comboActividad" @search:blur="blurActividad">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Actividades 14001'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosAspecto.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="condiciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Condiciones:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese las condiciones" v-model="datosAspecto.condiciones"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="impactos" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Impactos:" class="mb-2">
                                <b-form-textarea type="text" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los impactos" v-model="datosAspecto.impactos"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarAspecto = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalActualizarAspecto">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar aspecto </span>
            </h6>
            <CButtonClose @click="modalActualizarAspecto = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarAspecto)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProceso =>comboProceso.idProceso" @input="listarActividadesSGMA()" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarAspecto.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarAspecto.idProceso" :options="comboProceso" @search:blur="blurActualizarProceso">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="actividad" rules="required" v-slot="{errors}">
                            <b-form-group label="Actividad:" class="mb-2">
                                <v-select :reduce="comboActividad =>comboActividad.idActividad" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarAspecto.idActividad , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarAspecto.idActividad" :options="comboActividad" @search:blur="blurActualizarActividad">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosActualizarAspecto.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="condiciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Condiciones:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese las condiciones" v-model="datosActualizarAspecto.condiciones"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="impactos" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Impactos:" class="mb-2">
                                <b-form-textarea type="text" :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese los impactos" v-model="datosActualizarAspecto.impactos"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarAspecto = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoAspectos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "proceso",
                    label: "Proceso",
                    class: "text-center",
                },
                {
                    key: "actividad",
                    label: "Actividad",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaAspectos: [],
            modalRegistrarAspecto: false,
            modalActualizarAspecto: false,

            datosAspecto: {
                idProceso: null,
                idActividad: null,
                nombre: '',
                condiciones: '',
                impactos: '',
            },
            datosActualizarAspecto: {
                idAspecto: '',
                idProceso: null,
                idActividad: null,
                nombre: '',
                condiciones: '',
                impactos: '',
            },
            comboProceso: [],
            comboActividad: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarAspectosSGMA() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-aspectos-sgma", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.listaAspectos = response.data;
                    me.totalRows = me.listaAspectos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarActividadesSGMA() {
            let me = this;

            (me.datosAspecto.idActividad = null),
            (me.datosActualizarAspecto.idActividad = null),
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-actividades-procesos-sgma", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idProceso: me.datosAspecto.idProceso ? me.datosAspecto.idProceso : me.datosActualizarAspecto.idProceso
                    }
                })
                .then(function (response) {
                    me.comboActividad = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesoSGMA() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos-sgma", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.comboProceso = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarAspecto() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-aspecto-sgma", {
                        datosAspecto: me.datosAspecto,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarAspectosSGMA();
                    me.modalRegistrarAspecto = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarAspecto() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-aspecto-sgma", {
                        datosAspecto: me.datosActualizarAspecto,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarAspectosSGMA();
                    me.modalActualizarAspecto = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        abrirModalActualizarAspecto(param) {
            this.datosActualizarAspecto.idAspecto = param.item.idAspecto;
            this.datosActualizarAspecto.idProceso = param.item.idProceso;
            this.datosActualizarAspecto.idActividad = param.item.idActividad;
            this.datosActualizarAspecto.nombre = param.item.nombre;
            this.datosActualizarAspecto.condiciones = param.item.condiciones;
            this.datosActualizarAspecto.impactos = param.item.impactos;
            this.modalActualizarAspecto = true;
        },

        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurActividad() {
            this.computedForm.refs.actividad.validate();
        },
        blurActualizarProceso() {
            this.computedActualizarForm.refs.proceso.validate();
        },
        blurActualizarActividad() {
            this.computedActualizarForm.refs.actividad.validate();
        },
        eliminarAspecto(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el aspecto?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-aspecto-sgma", {
                            idAspecto: param.item.idAspecto,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarAspectosSGMA();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        resetModalRegistrarAspecto() {
            this.datosAspecto.idProceso = null;
            this.datosAspecto.idActividad = null;
            this.datosAspecto.nombre = '';
            this.datosAspecto.condiciones = '';
            this.datosAspecto.impactos = '';
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },
        resetModalActualizarAspecto() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarAspecto: function (val) {
            if (val == false) {
                this.resetModalRegistrarAspecto();
            }
        },
        modalActualizarAspecto: function (val) {
            if (val == false) {
                this.resetModalActualizarAspecto();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarAspectosSGMA();
            this.listarProcesoSGMA();
        }
    }

}
</script>
